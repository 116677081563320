.section-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 6rem 4rem;
    overflow: hidden;
    position: relative;

    .picture-and-cert{
        flex-basis: 40%;
        z-index: 1;
        .picture-of-me{
            display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 40%;
        align-self: center;
        position: relative;
        z-index: 0;
            img{
                border-radius: 50%;
        max-width: 400px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        border: 5px solid #fff;
        box-shadow: 1px 1px 35px 1px #ffffff;
            }
            .circle{
                position: absolute;
        max-width: 300px;
        width: 100%;
        height: 100%;
        border-radius: 0;
        transition: 1s;
        animation: circle_rotate 6s infinite linear;
        filter: blur(1px) contrast(.8);
            }
    
            .circle_1{
               animation-delay: 0s;
                background-color: #c40042;
                z-index: -1;
            }
            .circle_2{
                animation-delay: 1s;
                background-color: #df044e;
                z-index: -1;
            }
            .circle_3{
                animation-delay: 2s;
                background-color: #80042e;
                z-index: -1;
            }
            .circle_4{
                animation-delay: 3s;
                background-color: #c13262;
                z-index: -1;
            }
            .circle_5{
                animation-delay: 4s;
                background-color: #c40042;
                z-index: -1;
            
            }
            .circle_6{
                animation-delay: 5s;
                background-color: #6c0427;
                z-index: -1;
            }
        }
    }

    .about-me-text{
        flex-basis: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: .3s;
        z-index: 1;

        h1{
            font-size: 4rem;
            margin-bottom: 0rem;
            position: relative;
            line-height: 1;
            transition: .3s;
           
        }
        .user-title{
            font-family: poppins;
            font-weight: 500;
            font-size: 1.4rem;
            background: #c40042;
            padding: .5rem 1rem;
            width: 100%;
            max-width: 450px;
            margin: 10px 0 0;
            transition: .3s;
        }
        p{
            font-size: 1.5rem;
            transition: .3s;
        } 
    }

    &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        background: #11161d;
        z-index: 0;
    }
}
.tech-stack{
    padding: 4rem 0;
display: flex;
justify-content: flex-start;
width: 100%;
    .tech-stack-item{
        height: 170px;
border: 1px solid #9E9E9E;
padding: 1rem;
flex: 1;
// margin: 0 1rem;
    }
}

@keyframes circle_rotate{
    0%{
        transform: rotate(0deg) translateY(0%);
      
    }
    25%{
        transform: rotate(45deg) translateY(5%);
    }
    100%{
        transform: rotate(360deg) translateY(0%);
    }
}


@media all and (min-width: 1440px){
    .section-wrapper{
        .about-me-text p {
            padding-right: 8rem;
        }
    }
}

@media all and (max-width: 1600px){
    .section-wrapper{
        .picture-and-cert{
            flex-basis: 50%;
        }
        .about-me-text{
            flex-basis: 50%;
        }
    }
}

@media all and (max-width: 1580px){
    .section-wrapper{
        .certificationWrapper{margin-top: 30px;}  
        .picture-of-me{flex-basis: 50%;}
        .about-me-text{
            flex-basis: 50%;
            p{padding-right: 0rem;}
        }
    }
}

@media all and (max-width: 1400px){
    .section-wrapper{
        .picture-of-me{transform: scale(.8);}
        }
    }

    @media all and (max-width: 1200px){
        .section-wrapper{
            .picture-of-me{transform: scale(.8);}
            .about-me-text{
                p{font-size: 1.2rem;}
            }
            }
        }

@media all and (max-width: 1180px){
    .section-wrapper{
        .about-me-text{
             h1{
                font-size: 3rem
             }
             .user-title{
                font-size: 1rem;
                max-width: 70%;
            }
            }
        }
    }
    @media all and (max-width: 1080px){
        .section-wrapper{
            .picture-of-me{ transform: scale(0.7);}
            .about-me-text{
                 h1{
                    font-size: 2.5rem;
                    margin-top: 60px;
                 }
                 .user-title{
                    font-size: .8rem;
                    max-width: 55%;
                    padding: 0.2rem 1rem;
                }
                }
            }
        }
@media all and (max-width: 1024px){
    .section-wrapper{
        flex-direction: column;
        align-items: center;
        .certificationWrapper{margin-top: 80px;}  
        .picture-of-me{ transform: scale(1);}
        .about-me-text{
            text-align: center;
     
             h1{
                 font-size: 3rem;
                 margin-top: 80px;
             }
             p{
                 font-size: 1.2rem;
             }
             .user-title{
                margin: 10px auto 0;
                max-width: 250px;
            }
         }
    }
    
}



@media all and (max-width: 580px){
    .section-wrapper{
        flex-direction: column;
        align-items: center;
        padding: 6rem 2rem;
        .about-me-text{
            text-align: center;
     
             h1{
                font-size: 2.1rem;
             }
             p{
                 font-size: 1rem;
             }
         }
    }
    
}

@media all and (max-width: 480px){
    .section-wrapper{
        padding: 3rem 2rem;
        .certificationWrapper{
            margin-top: 30px;
            .certImages {
                width: 100px;
            }
        } 
        .picture-of-me{transform: scale(.8);}
        .about-me-text{
            h1{ margin-top: 30px;}
             .user-title{
                font-size: .8rem;
            }
         }
        }
    
}