.section_wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 1rem 1rem;
    grid-column-gap: 1rem;
    grid-row-gap: .5rem;
    
}
.folio_card{
    position: relative;
    cursor: pointer;
    transition: .3s;
}
.folio_card h3{
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
    transition: .3s;
}
.folio_card span{
text-transform: uppercase;
}
.folio_card img{ width: 100%;transition: .3s;box-shadow:8px 8px 7px 0px #111}
.folio_card:hover img{
    box-shadow:8px 8px 7px 0px #000;
    transform: translate(-4px, -2px);
}
.folio_card:hover .folio_title_descr{
    transform: translate(-4px, -2px);
}

.folio_title_descr{
    position: absolute;
    top:0px;
    padding: 2rem;
    transition: .3s;
}

@media all and (max-width: 2080px){
    .section_wrapper{
           grid-template-columns: 1fr 1fr 1fr 1fr;
       }
   }

@media all and (max-width: 1600px){
    .section_wrapper{
           grid-template-columns: 1fr 1fr 1fr;
       }
   }
@media all and (max-width: 1200px){
 .section_wrapper{
        grid-template-columns: 1fr 1fr;
    }
}
@media all and (max-width: 715px){
    .folio_card h3{
        font-size: 1.4rem;
       }
   }  

   @media all and (max-width: 615px){
    .folio_title_descr p{
        display: none;
       }
   } 
   @media all and (max-width: 515px){
    .folio_card h3{
        font-size: 1.2rem;
       }
   }

   @media all and (max-width: 515px){
    .folio_card h3{
        font-size: 1.4rem;
       }
    .section_wrapper{
        grid-template-columns: 1fr;
    }
    .folio_title_descr p{
        display: block;
       }
   } 

   @media all and (max-width: 515px){

   }