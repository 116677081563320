
.site-wrapper{
    display: block;
    position: relative;
}
body.loaded{
    animation: appear 2s linear 1s 1 forwards ;
    opacity: 0;
}


@keyframes appear {
    0%{
        opacity: 0
    }
    50%{
        opacity: 0
    }
    100%{
        opacity: 1
    }
}