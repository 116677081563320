.sidebar{
    background: rgba(14, 17, 24);
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 1;
}
.sidebar nav:after {
    content: "";
    width: 1px;
    background: #e91d62;
    height: 100%;
    position: absolute;
    left: 50%;
    transition: .2s;
    z-index: -1;
    opacity: 0;
    animation: animate-after-sidebar 2s infinite linear;
}

.sidebar nav, .sidebar div.social-links{
  background: rgba(14, 17, 24);
}

.sidebar ul{
    padding: 2rem 0;
    margin: 0;
    list-style: none;
}

.sidebar ul li{
    cursor: pointer;
    transition: .3s;
}
.sidebar ul li a {
    padding: 1rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 200;
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: #fff;
}
.sidebar ul li:hover,.sidebar ul li:active{
    background: #FA6862;
}



@keyframes animate-after-sidebar{
    0%{
        transform: translateY(-100%);
        opacity: 1;
        background-color: #0097A7;
    }
    
    95%{
        transform: translateY(100%);
        opacity: 0;
    }
    100%{
        opacity: 0;
        background-color: #fff;
        transform: translateY(-100%);
    }
}







@media all and (max-width: 768px){
.sidebar{
    top: auto;
    left: 0;
    right: 0;
    flex-direction: row;
    justify-content: space-evenly;
    width: auto;
}
.sidebar nav:after {
display: none;
}
.sidebar nav, .sidebar div.social-links{
    flex:1
}
.sidebar ul{
    display: flex;
    padding: 0;
} 
.sidebar ul li {
    flex: 1 1 100%;
}

}