.body-wrapper{
    width: calc(100% - 100px); 
}



@media all and (max-width:768px){

    .body-wrapper{
        width: auto;
    }
    
       
}